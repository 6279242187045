import '../scss/utils/NotFound.scss'
import notfound from '../utils/notfound.png'

const NotFound = ({role, action}) =>{
    return(
        <div className='notfound'>
            <img src={notfound} alt='notfound.png' />
            { role !== 'user' && <button onClick={action}> أضف </button>}
        </div>
    )
}

export default NotFound;