import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faBookmark, faCircleChevronUp, faCircleChevronDown, faLink, faChartSimple, faComment, faChevronLeft, faX } from '@fortawesome/free-solid-svg-icons';
import send from '../utils/others/send.png';
import '../scss/pages/EduMemes.scss';
import { SERVER } from "../config/config";
import useAuth from '../useAuth';
import Comment from '../hooks/Comment';
import extremelycanny from '../utils/mrincredible/extremelycanny.png'
import stats from '../utils/stats.png'
import loop from '../utils/loop.png'

const EduMemes = () => {
    const user = useAuth();
    const navigate = useNavigate();
    const { lessonID } = useParams();
    const [lesson, setLesson] = useState()
    const [eduMemes, setEduMemes] = useState([]);
    const [currentMemeIndex, setCurrentMemeIndex] = useState(0);
    const [currentMeme, setCurrentMeme] = useState();
    const [animationClass, setAnimationClass] = useState('');
    const [showInformations, setShowInformations] = useState(true);
    const [mediaAnimationClass, setMediaAnimationClass] = useState('');
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [iconSize, setIconSize] = useState('xl');
    const [openCommentSection, setOpenCommentSection] = useState(false)
    const [openStaticticSection, setOpenStatisticSection] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const updateIconSize = () => {
        if (window.innerWidth <= 768) {
            setIconSize('1x');
        } else {
            setIconSize('xl');
        }
        };

        updateIconSize();

        window.addEventListener('resize', updateIconSize);

        return () => window.removeEventListener('resize', updateIconSize);
    }, []);

    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    const [comment, setComment] = useState('');

    const [liked, setLiked] = useState(false);
    const [comments, setComments] = useState([]);
    const [saved, setSaved] = useState(false);

    const [view,setView] = useState(true)
    const [statistics, setStatistics] = useState([])
    const [touchStartY, setTouchStartY] = useState(0);

    const handleTouchStart = (e) => {
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        const touchEndY = e.changedTouches[0].clientY;
        const touchDiffY = touchStartY - touchEndY;
        if (touchDiffY > 100) {
            handleNextMeme(); 
        } else if (touchDiffY < -100) {
            handlePreviousMeme();
        }
    };

    useEffect(() => {
        const handleTouchEnd = (e) => handleTouchMove(e);

        window.addEventListener('touchstart', handleTouchStart);    
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [touchStartY]);

    useEffect(() => {
        const fetchEduMemes = async () => {
            try {
                const response = await axios.get(`${SERVER}/edumemes/edumemes`, {
                    params: {
                        lessonID
                    }
                });
                setEduMemes(response.data);
                const index = response.data.findIndex(item => item.ID === parseInt(localStorage.getItem('edumeme')))
                setCurrentMemeIndex(index);
                setCurrentMeme(response.data[index]);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchLesson = async () =>{
            try {
                const response = await axios.get(`${SERVER}/lessons/getLessonByID`, {
                    params: {
                        lessonID: lessonID,
                    }
                });
                setLesson(response.data);
            } catch (error) {
                console.log(error);
            }
        }


        fetchEduMemes();
        fetchLesson()
    }, [lessonID]);

    useEffect(() =>{
        setLiked(false)
        setSaved(false)
        const fetchInteractions = async () =>{
            try{
                const response = await axios.get(`${SERVER}/interact/getInteractions`, {
                    params: {
                        edumemeID : currentMeme.ID,
                        userUID : user.user.ID,
                    }
                });

                if (response.status === 200){
                    setComments(response.data.comments)
                    setLiked(response.data.liked)
                    setSaved(response.data.saved)
                }
            }catch(error){
                console.log(error)
            }
        }

        if (currentMeme){
            fetchInteractions();
            if (user.user.role !== 'user'){
                getStatistics()
            }
        }
    }, [currentMeme])

    useEffect(() => {
        if (!showInformations && videoRef.current) {
            videoRef.current.play();
        }
    }, [showInformations, currentMemeIndex]);

    const getStatistics = async() =>{
        try{
            const response = await axios.get(`${SERVER}/interact/statistics`, {
                params : {
                    edumemeID : currentMeme.ID,
                }
            })
            if (response.status === 200) {
                setStatistics(response.data)
            } 
        }catch(error){
            console.log(error)
        }
    }

    const handleNextMeme = () => {
        if (currentMemeIndex < eduMemes.length - 1) {
            setAnimationClass('slide-out-up');
            setTimeout(() => {
                setCurrentMemeIndex(currentMemeIndex + 1);
                setCurrentMeme(eduMemes[currentMemeIndex + 1])
                setAnimationClass('slide-in-up');
            }, 500);
        }
    };

    const handlePreviousMeme = () => {
        if (currentMemeIndex > 0) {
            setAnimationClass('slide-out-down');
            setTimeout(() => {
                setCurrentMemeIndex(currentMemeIndex - 1);
                setCurrentMeme(eduMemes[currentMemeIndex - 1])
                setAnimationClass('slide-in-down');
            }, 500);
        }
    };

    const handleGoBack = () => {
        navigate(`/lessons/${lesson.chapter_ID}/${lesson.ID}`);
    };

    if (eduMemes.length === 0) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return null;
    }

    const handleLike = async (e) => {
        e.preventDefault();
        try {
            if (liked){
                const response = await axios.delete(`${SERVER}/interact/dislike`, {
                params : {
                    edumemeID: currentMeme.ID,
                    userUID: user.user.ID
                }});
    
                if (response.status === 200) {
                    setLiked(false);
                }
            }else{
                const response = await axios.post(`${SERVER}/interact/like`, {
                    edumemeID : currentMeme.ID,
                    userUID: user.user.ID
                });
    
                if (response.status === 201) {
                    setLiked(true);
                }
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    const addComment = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${SERVER}/interact/comment`, {
                edumemeID : currentMeme.ID,
                userUID : user.user.ID,
                comment
            });
            if (response.status === 201) {
                setComments([response.data, ...comments]);
                setComment('');
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleCommentKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (comment.trim() === '') {
                return; // Do not add empty comments
            }
            try {
                const response = await axios.post(`${SERVER}/interact/comment`, {
                    edumemeID : currentMeme.ID,
                    userUID : user.user.ID,
                    comment
                });
    
                if (response.status === 201) {
                    setComments([response.data, ...comments]);
                    setComment('');
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            if (saved){
                const response = await axios.delete(`${SERVER}/interact/unsave`, {
                params : {
                    edumemeID : currentMeme.ID,
                    userUID : user.user.ID
                }});
    
                if (response.status === 200) {
                    setSaved(false);
                }
            }else{
                const response = await axios.post(`${SERVER}/interact/save`, {
                    edumemeID : currentMeme.ID,
                    userUID : user.user.ID
                });
    
                if (response.status === 201) {
                    setSaved(true);
                }
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    const displayStatistics = () =>{
        getStatistics()
        setView(false)
    }   
    const displayComments = () =>{
        setView(true)
    }

    const openComments = () =>{
        setOpenStatisticSection(false)
        setOpenCommentSection(true)
        setIsAnimating(true)
    }

    const openStatistics = () =>{
        setOpenCommentSection(false)
        setOpenStatisticSection(true)
        setIsAnimating(true)
    }

    const handleClose = () => {
        setIsAnimating(false);
        setTimeout(() => {
            setOpenCommentSection(false);
            setOpenStatisticSection(false)
            setIsAnimating(false);
        }, 700);
    };

    if (!user.user){
        return null;
    }else{
        return (
            <div className={`eduMeme ${animationClass}`}>
                <div className='info-section'>
                    <div className='main-section'>
                        <h2>{currentMeme.title}</h2>
                        <h3>{currentMeme.subtitle}</h3>
                        <h3>{localStorage.getItem('Chapter')}</h3>
                        <h3>{localStorage.getItem('Subject')}</h3>
                        <div className="container">
                            {user.user.role !== 'user' ?
                                (
                                    <div className="sections">
                                        <div>
                                            <button onClick={displayStatistics}>إحصائيات</button>
                                            {view === false ? <hr className="active"></hr> : <></>}
                                        </div>
                                        <div>
                                            <button onClick={displayComments}>التعليقات</button>
                                            {view === true ? <hr className="active"></hr> : <></>}
                                        </div>
                                    </div>
                                ) :
                                (
                                    <h3 style={{fontSize:'28px'}}>التعليقات</h3>
                                )   
                            }
                            <hr />
                            {view ? 
                                (
                                    <div className='comments-section'>
                                        <div className='comments'>
                                            {comments && comments.length > 0 ? (
                                                comments.map((commentObj, index) => (
                                                    <Comment key={index} userUID={commentObj.user_ID} comment = {commentObj.value} timespan = {commentObj.created_at} />
                                                ))
                                            ) : (
                                                <div className="nocomments">
                                                    <img src={extremelycanny} />
                                                    <span> كن انت الاسطورة الاولى و ضع تعليق </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='comment-field'>
                                            <input type='text' placeholder='أضف تعليق' value={comment} onChange={(e) => setComment(e.target.value)} onKeyDown={handleCommentKeyDown} />
                                            <button onClick={(e) => addComment(e)}><img src={send} alt='send' /></button>
                                        </div>
                                    </div>
                                ) : 
                                (
                                    <div className="statistical-section">
                                        <div className="this-post">
                                            <img src={stats} />
                                            <h3> عدد التفاعلات </h3>
                                            <div className="options">
                                                <div className="container">
                                                    <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                                    <span style={{color: '#2478DC' }}> الإعجابات </span>
                                                    <h4> {statistics && statistics.currentEduMeme.liked} </h4>
                                                </div>
                                                <div className="container">
                                                    <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                                    <span style={{color: '#2478DC' }}> التعليقات </span>
                                                    <h4> {statistics && statistics.currentEduMeme.comments }  </h4>
                                                </div>
                                                <div className="container">
                                                    <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                                    <span style={{color: '#2478DC' }}> الحفظ  </span>
                                                    <h4> {statistics && statistics.currentEduMeme.saved } </h4>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="this-post">
                                            <img src={loop} />
                                            <h3> المتوسط </h3>
                                            <div className="options">
                                                <div className="container">
                                                    <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                                    <span style={{color: '#2478DC' }}> الإعجابات </span>
                                                    <h4> {statistics && statistics.allEduMemes.liked }  </h4>
                                                </div>
                                                <div className="container">
                                                    <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                                    <span style={{color: '#2478DC' }}> التعليقات </span>
                                                    <h4> {statistics && statistics.allEduMemes.comments }  </h4>
                                                </div>
                                                <div className="container">
                                                    <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                                    <span style={{color: '#2478DC' }}> الحفظ  </span>
                                                    <h4> {statistics && statistics.allEduMemes.saved }  </h4>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                )
                            }
                        </div>
                        
                    </div>
                </div>
    
                <div className='interactions'>
                    <div onClick={(e) => handleLike(e)}>
                        <FontAwesomeIcon icon={faHeart} size={iconSize} style={liked ? { color: '#2478DC' } : currentMeme.video ? {color : 'white'} : {}} />
                        <span style={currentMeme.video ? {color : 'white'} : {}}>إعجاب</span>
                    </div>
                    <div onClick={(e) => openComments(e)} className="mobile-comments">
                        <FontAwesomeIcon icon={faComment} size={iconSize} style={currentMeme.video ? {color : 'white'} : {}} />
                        <span style={currentMeme.video ? {color : 'white'} : {}}>التعليقات</span>
                    </div>
                    {user.user.role !== 'user' ? (
                        <>
                            <div className="desktop-stat" onClick={(e) => displayStatistics(e)}>
                                <FontAwesomeIcon icon={faChartSimple} size={iconSize} />
                                <span style={currentMeme.video ? {color : 'white'} : {}}>إحصائيات</span>
                            </div>
                            <div className="mobile-stat" onClick={(e) => openStatistics(e)}>
                                <FontAwesomeIcon icon={faChartSimple} size={iconSize} color={currentMeme.video ? 'white' : 'black'} />
                                <span style={currentMeme.video ? {color : 'white'} : {}}>إحصائيات</span>
                            </div>
                        </>
                        ) : (
                            <div onClick={(e) => handleSave(e)}>
                                <FontAwesomeIcon icon={faBookmark} size={iconSize} style={saved ? { color: '#2478DC' } : currentMeme.video ? {color : 'white'} : {}} />
                                <span style={currentMeme.video ? {color : 'white'} : {}}>حفظ</span>
                            </div>
                        )
                    }
                    <div
                        onClick={handlePreviousMeme}
                        style={{ pointerEvents: currentMemeIndex === 0 ? 'none' : 'auto', opacity: currentMemeIndex === 0 ? 0.5 : 1 }}
                        className="up">
                        <FontAwesomeIcon icon={faCircleChevronUp} size={iconSize} />
                    </div>
                    <div
                        onClick={handleNextMeme}
                        style={{ pointerEvents: currentMemeIndex === eduMemes.length - 1 ? 'none' : 'auto', opacity: currentMemeIndex === eduMemes.length - 1 ? 0.5 : 1 }}
                        className="down">
                        <FontAwesomeIcon icon={faCircleChevronDown} size={iconSize} />
                    </div>
                    <a className="external-link" href={`https://${currentMeme.link}`} target='_blank' rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLink} style={{ color: "#ffffff", }} size="xs" />
                    </a>
                </div>
    
                <div className={`media-section ${mediaAnimationClass} ${currentMeme.video ? 'dark-mode' : ''}`}>
                    <div className="mobile-title"> 
                        <h2 style={currentMeme.video ? {color : 'white'} : {}}>{currentMeme.title}</h2>
                        <h3 style={currentMeme.video ? {color : '#2478DC'} : {}}>{currentMeme.subtitle}</h3>
                    </div>
                    <div className='actions'>
                        <button onClick={handleGoBack}><FontAwesomeIcon icon={faChevronLeft} size='2xl' style={{ color: currentMeme.video ? 'white' : 'black' }} /></button>
                    </div>
                    {currentMeme.dataType === 'text/image' ? (
                        <div className='informations'>
                            <img src={SERVER+currentMeme.media} alt='meme' />
                            <span>{currentMeme.text}</span>
                        </div>
                    ) : (
                        <div className='meme'>
                            <video
                                ref={videoRef}
                                src={SERVER+currentMeme.media}
                                controls={false}
                                onClick={handleVideoClick}
                                autoPlay
                            />
                        </div>
                    )}
    
                    <div className="mobile-details">
                        <h2 style={currentMeme.video ? {color : 'white'} : {}}>{localStorage.getItem('Chapter')}</h2>
                        <h3 style={currentMeme.video ? {color : '#2478DC'} : {}}>{localStorage.getItem('Subject')}</h3>
                    </div>
                </div>
                
                {openCommentSection && (
                    <>
                        <div className="overlay" onClick={() => handleClose()}></div> 
                        <div className={`mobile-open-card ${isAnimating ? 'slide-in' : 'slide-out'}`}> 
                            <div className="line"></div>
                            <div className='comments'>
                                {comments.length > 0 ? (
                                    comments.map((commentObj, index) => (
                                        <Comment key={index} userUID={commentObj.user_ID} comment = {commentObj.value} timespan = {commentObj.created_at} />
                                    ))
                                ) : (
                                    <div className="nocomments">
                                        <img src={extremelycanny} />
                                        <span> كن انت الاسطورة الاولى و ضع تعليق </span>
                                    </div>
                                )}
                            </div>
                            <div className='comment-field'>
                                <input type='text' placeholder='أضف تعليق' value={comment} onChange={(e) => setComment(e.target.value)} onKeyDown={handleCommentKeyDown} />
                                <button onClick={(e) => addComment(e)}><FontAwesomeIcon icon={faCircleChevronUp} size="xl" /></button>
                            </div>
                        </div>
                    </>
                    
                )}
    
                {openStaticticSection && (
                    <>
                        <div className="overlay" onClick={() => handleClose()}></div> 
                        <div className={`mobile-open-card ${isAnimating ? 'slide-in' : 'slide-out'}`}> 
                            <div className="statistical-section">
                                <div className="this-post">
                                    <img src={stats} />
                                    <h3> عدد التفاعلات </h3>
                                    <div className="options">
                                        <div className="container">
                                            <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                            <span style={{color: '#2478DC' }}> الإعجابات </span>
                                            <h4> {statistics && statistics.currentEduMeme.liked} </h4>
                                        </div>
                                        <div className="container">
                                            <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                            <span style={{color: '#2478DC' }}> التعليقات </span>
                                            <h4> {statistics && statistics.currentEduMeme.comments }  </h4>
                                        </div>
                                        <div className="container">
                                            <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                            <span style={{color: '#2478DC' }}> الحفظ  </span>
                                            <h4> {statistics && statistics.currentEduMeme.saved } </h4>
                                        </div>
                                    </div>
                                </div> 
                                <div className="this-post">
                                    <img src={loop} />
                                    <h3> المتوسط </h3>
                                    <div className="options">
                                        <div className="container">
                                            <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                            <span style={{color: '#2478DC' }}> الإعجابات </span>
                                            <h4> {statistics && statistics.allEduMemes.liked }  </h4>
                                        </div>
                                        <div className="container">
                                            <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                            <span style={{color: '#2478DC' }}> التعليقات </span>
                                            <h4> {statistics && statistics.allEduMemes.comments }  </h4>
                                        </div>
                                        <div className="container">
                                            <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                            <span style={{color: '#2478DC' }}> الحفظ  </span>
                                            <h4> {statistics && statistics.allEduMemes.saved }  </h4>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </>
                )}
                
            </div>
        );
    }
}

export default EduMemes;
