// NotAllowed.js
import React from 'react';
import '../scss/utils/NotAllowed.scss';
import superUncanny from '../utils/mrincredible/superuncanny.png'

const NotAllowed = () => {
  return (
    <div className='not-allowed'>
        <img src={superUncanny} alt='uncanny' />
    </div>
  );
};

export default NotAllowed;
