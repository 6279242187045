// src/api/userApi.js

import axios from 'axios';
import { SERVER } from './config';
import Cookies from 'js-cookie';

const API_URL = `${SERVER}/auth/user`; 

export const getUserInfo = async () => {
    try {
      const token = Cookies.get('token');
      const response = await axios.get(API_URL,
        { headers: { "Authorization": `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.error('Error fetching user info:', error);
      throw error;
    }
  };
