// EditOptionsModal.jsx
import '../scss/utils/EditOptionsModal2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER } from "../config/config";
import { useState, useEffect, useRef } from 'react';

const EditDescriptionModal = ({ show, onClose, subject, chapter }) => {
    const modalRef = useRef(null);
    const maxChars = 200;

    const [description, setDescription] = useState(chapter && chapter.description)
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    if (!show) {
        return null;
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const handleReset = () =>{
        setDescription(chapter.description)
    } 

    const editLink = async (e) =>{
        e.preventDefault()
        try{
            await axios.put(`${SERVER}/chapters/editChapterDescription`, {
                chapterID : chapter.ID,
                description
            })
            window.location.reload()
        }catch(e){
            console.log(e)
        }
    }

    return (
        <div className="modal-overlay">
            <div className="modal" ref={modalRef} onClick={handleModalClick}>
                <div className="modal-input">
                    <label> وصف </label>
                    <div>
                        <textarea
                            placeholder="وصف"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            maxLength={maxChars}
                        />
                        <div className="char-count">{description ? (maxChars - description.length) + '/' + maxChars : '0/' + maxChars}</div>
                    </div>
                </div>
                <div className="modal-buttons">
                    <button id="modal-submit-button" onClick={(e) => editLink(e)} className="modal-button">حفظ</button>
                    <button id="modal-reset-button" onClick={handleReset} className="modal-button">رجوع</button>
                </div>
            </div>
        </div>
    );
};

const EditOptionsModal2 = ({ chapter, subject }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleChapterEdit = (e) => {
        e.stopPropagation();
        navigate(`/chapterManagement/${chapter.subject_ID}/${chapter.ID}`);
    };

    const handleDescriptionEdit = (e) => {
        e.stopPropagation()
        setShowModal(true);
    }

    const changeChapterStatus = async (e) =>{
        e.stopPropagation();
        try{
            await axios.put(`${SERVER}/chapters/editChapterStatus`, {
                chapterID : chapter.ID
            });
        }catch(error){
            console.log(error)
        }
    }

    const handleSubjectEdit = (e) =>{
        e.stopPropagation();
        navigate(`/subjectManagement/${subject.ID}`);
    };

    const changeSubjectStatus = async (e) => {
        e.stopPropagation();
        try {
            const ID = subject.ID;
            await axios.put(`${SERVER}/subjects/editSubjectStatus`, { ID }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="edumeme-options-modal2">
            <ul>
                {chapter && (
                    <>
                        <li onClick={handleChapterEdit}>
                            <span>تعديل</span>
                            <FontAwesomeIcon icon={faPen} style={{ color: "#ffffff" }} size='xs' />
                        </li>
                        <li onClick={(e) => handleDescriptionEdit(e)}>
                            <span>تعديل وصف المحور</span>
                            <FontAwesomeIcon icon={faPen} style={{ color: "#ffffff" }} size='xs' />
                        </li>
                        <li onClick={(e) => changeChapterStatus(e)}>
                            <span>تغيير إلى غير متوفر</span>
                            <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: "#ffffff" }} />
                        </li>
                    </>
                )}
                {subject && (
                    <>
                        <li onClick={(e) => handleSubjectEdit(e)}>
                            <span>تعديل</span>
                            <FontAwesomeIcon icon={faPen} style={{ color: "#ffffff" }} size='xs' />
                        </li>
                        <li onClick={(e) => changeSubjectStatus(e)}>
                            <span>تغيير إلى غير متوفر</span>
                            <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: "#ffffff" }} />
                        </li>
                    </>
                )}
            </ul>
            <EditDescriptionModal
                show={showModal}
                onClose={() => setShowModal(false)}
                subject = {subject}
                chapter = {chapter}
            />
        </div>
    );
};

export default EditOptionsModal2;
