import React, { useState, useEffect } from "react";
import axios from "axios";
import '../scss/Home.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faYoutube, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import mainImage from '../utils/main_page/main.png'
import logo from '../utils/logo1.png'; 
import canny from '../utils/mrincredible/canny.png'
import uncanny from '../utils/mrincredible/uncanny.png'
import extremelyCanny from '../utils/mrincredible/extremelycanny.png'
import meme1 from '../utils/main_page/image5.png'
import meme2 from '../utils/main_page/image6.png'
import einstein from '../utils/main_page/phy.png'
import Navbar from "../hooks/Navbar";
import { useNavigate } from "react-router-dom";
import useAuth from "../useAuth";

function Home() {
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [navbarClosing, setNavbarClosing] = useState(false);
  const { user, loading } = useAuth();   

  useEffect(() =>{
    if (user){
      setIsAuthenticated(true)
    }
  }, [user])

  const openNavbar = () => {
      setNavbarClosing(false);
      setNavbarVisible(true);
  };

  const closeNavbar = () => {
      setNavbarClosing(true);
      setTimeout(() => {
          setNavbarVisible(false);
      }, 500);
  };

  const Profile = (e) =>{
      e.preventDefault()
      navigate('/Profile')
  }
  return (
    <div className="Home">
      {
        isAuthenticated ? (
          <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
        ) : (<></>)
      }
      {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
      <div className="test">
        <div className='auth'>
          <div>
            {isAuthenticated ? (
              <div className="auth-attributes" onClick={(e) => Profile(e)}>
                <span id="user">مرحبا، {user.username}</span>
              </div>
            ) : (
              <div className="auth-attributes">
                <a id="login" href="/authentication/login">تسجيل الدخول</a>
                <a id="signup" href="/authentication/signup"> إنشاء حساب </a>
              </div>
            )}
          </div>
        </div>
        <div id="title">
          <a href="/">الميم و التعليم</a>
        </div>
      </div>
      

      <div className="primary-style">
        <div className="main">
        <div className="main-description">
            <img src={logo} />
            <span>قم بتحميل تطبيق الميم والتعليم</span>
            <h1>التطبيق الذي لا يفرق بين التعلم والمتعة</h1>
            <div className="completion">
              <div className="completion-style">
                <div className="text">
                  <span> Donwload on the </span>
                  <h4>Google Play</h4>
                </div>
                <FontAwesomeIcon icon={faGooglePlay} style={{color:"#2478DC"}} size="xl" />
              </div>
              <div className="completion-style">
                <div className="text">
                  <span> Donwload on the </span>
                  <h4>App Store</h4>
                </div>
                <FontAwesomeIcon icon={faApple} style={{color:"#2478DC"}} size="xl" />
              </div>
            </div>
          </div>
          <div className="image-container">
            <img src={mainImage} />
          </div>
        </div>
      </div>
      <div className="secondary-style">
        <div className="aboutus">
          <h1>ماهو تطبيق الميم والتعليم</h1>
          <div className="description">
            <div className="description-style">
              <img src={uncanny} />
              <span>الميم و التعليم هو تطبيق</span>
              <span>تعليمي موجه لتلاميذ</span>
              <span>التعليم الأساسي و الثانوي</span>
            </div>
            <div className="description-style">
              <img src={canny} />
              <span>يعتمد على الميمز و التعديلات</span>
              <span>لإيصال المعلومة بطريقة</span>
              <span>ممتعة</span>
            </div>
            <div className="description-style">
              <img src={canny} />
              <span>تصميم التطبيق يعطيك</span>
              <span>إحساس تصفح أحد مواقع</span>
              <span>التواصل الإجتماعي حتى لا</span>
              <span>تشعر بالملل عند إستعماله</span>
              <span>للدراسة</span>
            </div>
          </div>
          <div className="conclusion">
            <img src={extremelyCanny} />
            <div>
              <h3>التطبيق متوفر الآن على
                  متجر الأندرويد والآيفون
              </h3>
              <div>
                <div className="completion-style">
                  <div className="text">
                    <span> Donwload on the </span>
                    <h4>Google Play</h4>
                  </div>
                  <FontAwesomeIcon icon={faGooglePlay} style={{color:"#F4F5F9"}} size="xl" />
                </div>
                <div className="completion-style">
                    <div className="text">
                      <span> Donwload on the </span>
                      <h4>App Store</h4>
                    </div>
                    <FontAwesomeIcon icon={faApple} style={{color:"#F4F5F9"}} size="xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="primary-style">
        <div className="objectif">
          <h1>ما هو هدفنا؟</h1>
          <h3>لماذا نقضي ساعات في مواقع التواصل الاجتماعي أو في اللعب و لا نشعر بالوقت؟ لكن عندما
              نحاول المراجعة، نشعر ببطئ كل دقيقة تمر؟
          </h3>
          <div className="memes">
            <div className="meme">
              <span>أنا و الزمرة عندما نتفق على قضاء
                    الليلة بأكملها في اللعب عبر الانترنت
              </span>
              <img src={meme1} />
            </div>
            <div className="meme">
              <span>أنا و الزمرة عندما نحاول المراجعة
                    لمدة نصف ساعة
              </span>
              <img src={meme2} />
            </div>
          </div>
          <h1>هدفنا ببساطة هو جعل المراجعة تجربة
              ممتعة للتلميذ
          </h1>
        </div>
      </div>
      <div className="secondary-style">
        <div id="quote">
          <h1>
            "لا تفرق بين العمل واللعب. اعتبر كل ما تفعله بمثابة لعب، ولا تتخيل ولو لدقيقة واحدة أنك يجب أن تكون جاداً"  
          </h1>
          <h1>
            آلان واتس 
          </h1>
        </div>
      </div>
      <div className="primary-style">
        <div className="social-media">
          <h1>تابعنا على منصات التواصل الاجتماعي</h1>
          <h3>نقوم بنشر الميمز المتعلقة بالتلاميذ و التعليم</h3>
          <div className="icons">
            <a href="#"><FontAwesomeIcon icon={faInstagram} style={{color: "#F4F5F9",}} size="2xl" /></a>
            <a href="#"><FontAwesomeIcon icon={faFacebookF} style={{color: "#F4F5F9",}} size="2xl" /></a>
            <a href="#"><FontAwesomeIcon icon={faTiktok} style={{color: "#F4F5F9",}} size="2xl" /></a>
            <a href="#"><FontAwesomeIcon icon={faYoutube} style={{color: "#F4F5F9",}} size="2xl" /></a>
          </div>
        </div>
      </div>
      <div className="secondary-style">
        <div className="rights">
          <div className="einstein">
            <img src={einstein} />
            <div className="apps">
              <h2>لا تنسى تحميل التطبيق</h2>
              <div className="completion">
                <div className="completion-style">
                  <div className="text">
                    <span> Donwload on the </span>
                    <h4>Google Play</h4>
                  </div>
                  <FontAwesomeIcon icon={faGooglePlay} style={{color:"#F4F5F9"}} size="xl" />
                </div>
                <div className="completion-style">
                  <div className="text">
                    <span> Donwload on the </span>
                    <h4>App Store</h4>
                  </div>
                  <FontAwesomeIcon icon={faApple} style={{color:"#F4F5F9"}} size="xl" />
                </div>
              </div>
            </div>
            <img src={einstein} />
          </div>
          <span>جميع الحقوق محفوظة<div>©</div></span>
        </div>
      </div>
    </div>
  );
}

export default Home;
