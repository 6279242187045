import '../scss/utils/EditOptionsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faCheck, faChartSimple, faEyeDropper } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER } from '../config/config';
import { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const EditLinkModal = ({ show, onClose, edumeme }) => {
    const modalRef = useRef(null);
    const [link, setLink] = useState(edumeme.link)
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    if (!show) {
        return null;
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const handleReset = () =>{
        setLink(edumeme.link)
    } 

    const editLink = async (e) =>{
        e.preventDefault()

        try{
            await axios.put(`${SERVER}/edumemes/editEdumemeLink`, {
                edumemeID : edumeme.ID,
                link
            })
            window.location.reload()
        }catch(e){
            console.log(e)
        }
    }

    return (
        <div className="modal-overlay">
            <div className="modal" ref={modalRef} onClick={handleModalClick}>
                <div className="modal-input">
                    <label>
                        تعديل الموقع    
                    </label>
                    <input
                        type="text"
                        value={link}
                        placeholder="تعديل الموقع"
                        onChange={(e) => setLink(e.target.value)}
                    />
                </div>
                <div className="modal-buttons">
                    <button id="modal-submit-button" onClick={(e) => editLink(e)} className="modal-button">حفظ</button>
                    <button id="modal-reset-button" onClick={handleReset} className="modal-button">رجوع</button>
                </div>
            </div>
        </div>
    );
};

const EditOptionsModal = ({ edumeme }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    
    const handleEdit = (e) => {
        e.stopPropagation();
        navigate(`/edumememanagement/${edumeme.lesson_ID}/${edumeme.ID}`);
    };

    const handleDelete = async (e) => {
        e.stopPropagation();
        confirmAlert({
            title: 'تأكيد الحذف',
            message: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
            buttons: [
                {
                    label: 'نعم',
                    onClick: async () => {
                        try {
                            const response = await axios.delete(`${SERVER}/edumemes/deleteEdumeme`, {
                                params: {
                                    edumemeID : edumeme.ID
                                }
                            });
                            if (response.status === 200) {
                                toast.success('تم حذف العنصر بنجاح');
                                window.location.reload();
                            }
                        } catch (error) {
                            console.log(error);
                            toast.error('فشل في حذف العنصر');
                        }
                    }
                },
                {
                    label: 'لا',
                    onClick: () => {}
                }
            ]
        });
    };

    const handleLinkChange = (e) => {
        e.stopPropagation();
        setShowModal(true);
    };

    const setAvailable = async (e) => {
        e.stopPropagation();
        try{
            await axios.put(`${SERVER}/edumemes/editEdumemeStatus`, {
                edumemeID : edumeme.ID
            })
            window.location.reload()
        }catch(e){
            console.log(e)
        }
    };

    const statistiques = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="edumeme-options-modal">
            <ul>
                {edumeme && (
                    <>
                        <li onClick={handleEdit}>
                            <span>تعديل</span>
                            <FontAwesomeIcon icon={faPen} style={{ color: "#ffffff" }} size='xs' />
                        </li>
                        <li onClick={handleLinkChange}>
                            <span>تعديل الموقع</span>
                            <FontAwesomeIcon icon={faPen} style={{ color: "#ffffff" }} size='xs' />
                        </li>
                        <li onClick={handleDelete}>
                            <span>حذف</span>
                            <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: "#ffffff" }} />
                        </li>
                        <li onClick={setAvailable}>
                            <span>تغيير إلى غير متوفر</span>
                            <FontAwesomeIcon icon={faCheck} size="xs" style={{ color: "#ffffff" }} />
                        </li>
                        <li onClick={statistiques}>
                            <span>إحصائيات</span>
                            <FontAwesomeIcon icon={faChartSimple} size="xs" style={{ color: "#ffffff" }} />
                        </li>
                    </>
                )}
            </ul>

            <EditLinkModal
                show={showModal}
                onClose={() => setShowModal(false)}
                edumeme = {edumeme}
            />
            <ToastContainer />
        </div>
    );
};

export default EditOptionsModal;
