import '../scss/utils/Card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import EditOptionsModal2 from './EditOptionsModal2';
import { SERVER } from '../config/config';

const Card = ({ role, chapter, subject }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);

    const handleSubjectClick = () => {
        if (subject && subject.ready) {
            localStorage.setItem('Subject', subject.title)
            navigate(`/chapters/${subject.ID}`);
        }
    };

    const handleChapterClick = () => {
        if (chapter && chapter.ready) {
            localStorage.setItem('Chapter', chapter.title)
            navigate(`/lessons/${chapter.subject_ID}/${chapter.ID}`);
        }
    };

    const toggleModal = (event) => {
        event.stopPropagation();
        setShowModal(!showModal);
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (showModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal]);

    return subject ? (
        <div className='subject-card' onClick={handleSubjectClick}>
            {!subject.ready && (
                <FontAwesomeIcon className='ready' icon={faLock} />
            )}

            <img src={SERVER+subject.img} alt={subject.title} />
            <h1>{subject.title}</h1>
            { role !== 'user' && <button onClick={toggleModal}><FontAwesomeIcon icon={faEllipsisVertical} size='xl' /></button>}
            {showModal && (
                <div ref={modalRef}>
                    <EditOptionsModal2 subject={subject} />
                </div>
            )}
        </div>
    ) : (
        <div className='chapter-card' onClick={handleChapterClick}>
            {!chapter.ready && (
                <FontAwesomeIcon className='ready' icon={faLock} />
            )}
            <img src={SERVER+chapter.img} alt={chapter.title} />
            <div>
                <h1>{chapter.title}</h1>
                <span className='custom-span'> {chapter.description} </span>
            </div>
            { role !== 'user' && <button onClick={toggleModal}><FontAwesomeIcon icon={faEllipsisVertical} size='xl' /></button>}
            {showModal && (
                <div ref={modalRef}>
                    <EditOptionsModal2 chapter={chapter} />
                </div>
            )}
        </div>
    );
};

export default Card;
