import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../../hooks/Navbar';
import '../../scss/pages/administration/EditProfile.scss'
import { useNavigate } from 'react-router-dom';
import useAuth from '../../useAuth';

const EditProfile = () =>{
    const navigate = useNavigate()
    const {user, loading} = useAuth();
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const changeUsername = (e) =>{
        navigate(`/ProfileManagement/username/${user.id}`)
    }

    const changeEmail = (e) =>{
        navigate(`/ProfileManagement/email/${user.id}`)
    }

    const changePassword = (e) =>{
        navigate(`/ProfileManagement/password/${user.id}`)
    }

    const logout = () => {
        localStorage.clear()
        navigate('/');
    };

    if(loading){

    }else{
        return (
            <div className='EditManagement'>
                <div className="navigation">
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
    
                <h1> تعديل الحساب </h1>
                <div className='options'>
                    <button className='card' onClick={(e) => changeUsername(e)}>
                    إسم المستخدم    
                    </button>
    
                    <button className='card' onClick={(e) => changeEmail(e)}>
                    البريد الإلكتروني
                    </button>
    
                    <button className='card' onClick={(e) => changePassword(e)}>
                    كلمة المرور
                    </button>
    
                    <button className='card' onClick={(e) => logout(e)}>
                    تسجيل الخروج
                    </button>
                </div>
    
            </div>
            
        )
    }
}

export default EditProfile;