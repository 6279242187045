import React, { useEffect, useState, useRef } from "react";
import '../scss/pages/Subjects.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { useNavigate } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import Card from "../hooks/Card";
import NotFound from "../hooks/NotFound";
import Navbar from '../hooks/Navbar';

const Subjects = () => {
    localStorage.removeItem('Subject')
    const navigate = useNavigate();
    const user = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${SERVER}/subjects/subjects`);
                setSubjects(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSubjects();

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    if (!user.user) {
        return;
    }

    const addSubject = () => {
        navigate('/subjectManagement');
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };
    
    return (
        <div className="subjects">
                <div className="navigation">
                    { user.user.role !== 'user' && <button id="add"><FontAwesomeIcon icon={faPlus} onClick={addSubject} size="xl" /></button>}
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
            {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
            <div className="main">
                <h1>المواد</h1>
                <div className="levels" onClick={toggleDropdown}>
                    <span>المستوى: {localStorage.getItem('level') || 'اختر المستوى'}</span>
                    <button onClick={toggleDropdown}><FontAwesomeIcon icon={faFilter} /></button>
                    {dropdownVisible && (
                        <div ref={dropdownRef} className="dropdown">
                        </div>
                    )}
                </div>
                <div className="subjects-section">
                    {subjects.length > 0 ? (
                        subjects.map((subject, index) => (
                            <Card
                                key={index}
                                className='subject-card'
                                subject={subject}
                                role={user.user.role}
                            />
                        ))
                    ) : (
                        <NotFound role={user.user.role} action={addSubject} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Subjects;
