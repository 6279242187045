import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../hooks/Navbar";
import '../../scss/pages/administration/ProfileManagement.scss'
import axios from "axios";
import { SERVER } from "../../config/config";
import useAuth from "../../useAuth";

const ProfileManagement = () =>{
    const navigate = useNavigate();
    const { type } = useParams();
    const {user, loading} = useAuth()

    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);

    const [username, setUsername] = useState()
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const changeUsername = async (e) =>{
        e.preventDefault()
        try{
            const response = await axios.put(`${SERVER}/user/changeUsername`, {
                userUID : user.ID,
                username
            })

            if (response.status === 200){
                navigate('/Profile')
                user.username = username
                localStorage.setItem('user', JSON.stringify(user));
            }
        }catch(e){
            console.log(e)
        }
    }

    const changeEmail = async (e) =>{
        e.preventDefault()
        try{
            const response = await axios.put(`${SERVER}/user/changeEmail`, {
                userUID : user.ID,
                email,
                password
            })

            if (response.status === 200){
                navigate('/Profile')
                user.email = email
                localStorage.setItem('user', JSON.stringify(user));
            }
        }catch(e){
            console.log(e)
        }
    }

    const changePassword = async (e) =>{
        e.preventDefault()
        try{
            const response = await axios.put(`${SERVER}/user/changePassword`, {
                userUID : user.ID,
                password,
                newPassword
            })

            if (response.status === 200){
                navigate('/Profile')
            }
        }catch(e){
            console.log(e)
        }
    }

    const resetFields = () => {
        setUsername(undefined)
        setEmail(undefined)
        setPassword(undefined)
        setNewPassword(undefined)
    }

    if(loading){

    }else{
        return(
            <div className="profile-management">
                <div className="navigation">
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                {
                    type === 'username' ? (
                        <div className="container">
                            <h1> تعديل إسم المستخدم </h1>
                            <form>
                                <div className="input">
                                    <label> إسم المستخدم </label>
                                    <input type="text" placeholder="إسم المستخدم" onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <span> يجب على إسم المستخدم أن يحتوي فقط على أحرف و أرقام و شرطات سفلية و واصلات. </span>
    
                                <div className="buttons">
                                    <button id='save' onClick={(e) => changeUsername(e)}> حفظ </button>
                                    <button id="reset" type="reset" onClick={resetFields}> رجوع </button>
                                </div>
                            </form>
                        </div>
                    ) : type === 'email' ? (
                        <div className="container">
                            <h1> تعديل البريد الإلكتروني </h1>
                            <form>
                                <div className="input">
                                    <label> البريد الإلكتروني الجديد </label>
                                    <input type="email" placeholder="البريد الإلكتروني الجديد" onChange={(e) => setEmail(e.target.value)} />
                                </div>
    
                                <div className="input">
                                    <label> كلمة المرور </label>
                                    <input type="password" placeholder="كلمة المرور" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="buttons">
                                    <button id='save' onClick={(e) => changeEmail(e)}> حفظ </button>
                                    <button id="reset" type="reset" onClick={resetFields}> رجوع </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="container">
                            <h1> تعديل كلمة المرور </h1>
                            <form>
                                <div className="input">
                                    <label> كلمة المرور الحالية </label>
                                    <input type="password" placeholder="كلمة المرور الحالية" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                
                                <div className="input">
                                    <label> كلمة المرور الجديدة </label>
                                    <input type="password" placeholder="كلمة المرور الجديدة" onChange={(e) => setNewPassword(e.target.value)} />
                                </div>
                                <div className="buttons">
                                    <button id='save' onClick={(e) => changePassword(e)}> حفظ </button>
                                    <button id="reset" type="reset" onClick={resetFields}> رجوع </button>
                                </div>
                            </form>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default ProfileManagement;