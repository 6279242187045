import React, { useEffect, useState, useRef } from "react";
import '../scss/pages/Lessons.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMedal, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../hooks/Navbar';
import EduMemeCard from "../hooks/EduMemeCard";
import NotFound from "../hooks/NotFound";
import useAuth from '../useAuth';

const AddLessonModal = ({ show, onClose, lesson, setLesson, edit, setEdit, onAddLesson, onEditLesson }) => {
    const modalRef = useRef(null);
    
    const handleAddLesson = () => {
        if (edit){
            onEditLesson(lesson);
            setEdit(false)
        }else{
            onAddLesson(lesson);
        }
        setLesson("");
        onClose();
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal" ref={modalRef}>
                <h2>أضف درس</h2>
                <div className="modal-input">
                    <label>
                        عنوان الدرس 
                    </label>
                    <input
                        type="text"
                        value={lesson}
                        placeholder="عنوان الدرس"
                        onChange={(e) => setLesson(e.target.value)}
                    />
                </div>
                <div className="modal-buttons">
                    <button id="modal-submit-button" onClick={handleAddLesson} className="modal-button">حفظ</button>
                    <button id="modal-reset-button" onClick={onClose} className="modal-button">رجوع</button>
                </div>
            </div>
        </div>
    );
};

const Lessons = () => {
    const {user, loading} = useAuth();
    const [lesson, setLesson] = useState("");
    const [lessons, setLessons] = useState([])
    const [edit, setEdit] = useState(false)
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const { subjectID, chapterID } = useParams();

    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    
    const [chapter, setChapter] = useState();
    const [eduMemes, setEduMemes] = useState();
    
    useEffect(() => {
        const fetchChapterData = async () => {
            try {
                const response = await axios.get(`${SERVER}/chapters/getChapter`, {
                    params: {
                        chapterID
                    }
                });
                const responseDone = await axios.get(`${SERVER}/interact/getChapterDone`, {
                    params:{
                        chapterID,
                        userUID: user.ID
                    }
                })

                if (response.status === 200){
                    response.data.done = responseDone.data.done
                }
                setChapter(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchLessons = async () =>{
            try {
                const response = await axios.get(`${SERVER}/lessons/getLessons`, {
                    params: {
                        chapterID: chapterID
                    }
                });
                setLessons(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        if(chapterID && subjectID && user){
            fetchChapterData();
            fetchLessons();    
        }
    }, [chapterID, subjectID, user]);

    useEffect(()=>{
        const fetchEduMemes = async (lessonID) => {
            try {
                const response = await axios.get(`${SERVER}/edumemes/edumemes`, {
                    params: {
                        lessonID: lessonID
                    }
                });
                setEduMemes(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        if (lessons.length>0){
            for (const lesson of lessons){
                fetchEduMemes(lesson.ID);
            }
        }
    }, [lessons])

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const addLesson = async () => {
        try {    
            await axios.post(`${SERVER}/lessons/addLesson`, { 
                chapterID : chapterID,
                lesson : lesson
            });
            setLesson("");
            setShowModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const DoneLesson = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (chapter.done === false){
                response = await axios.post(`${SERVER}/interact/chapterDone`, { 
                    chapterID: chapterID,
                    userUID : user.ID
                });
            }else{
                response = await axios.delete(`${SERVER}/interact/chapterUndone`, { params: { 
                    chapterID: chapterID,
                    userUID : user.ID
                }});
            }
            
    
            if (response.status === 200) {
                setChapter(prevChapter => ({
                    ...prevChapter,
                    done: !prevChapter.done
                }));
            }

            if (response.status === 201) {
                setChapter(prevChapter => ({
                    ...prevChapter,
                    done: !prevChapter.done
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };
    

    const addEduMeme = (lessonID) => {
        navigate(`/edumememanagement/${lessonID}`);
    };

    const showEditLesson = (lesson) => {
        setLesson(lesson)
        setEdit(true)
        setShowModal(true)
    };

    const editLesson = () =>{
        
    }   

    if (!user) {
        return null;
    }else{
        return (
            <div className="edumeme">
                <div className="navigation">
                    { user.role !== 'user' ? 
                        (
                            <button id="add" onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faPlus} size="xl" /></button>
                        ) : (
                            chapter && chapter.done === false ? (
                                <button id="finish" onClick={(e) => DoneLesson(e)}> وضع علامة أكملت </button>
                            ) : (
                                <button id="done" onClick={(e) => DoneLesson(e)}> <FontAwesomeIcon icon={faMedal} size="lg" style={{marginLeft:'5px'}} /> أكملت هذا المحور </button>
                            )
                            
                        )}
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                <div className="main">
                    <div className="chapter-details">
                        <img src={chapter && SERVER+chapter.img} alt="Chapter" />
                        <div>
                            <h2>{chapter && chapter.title}</h2>
                            <span>{chapter && chapter.description}</span>
                        </div>
                    </div>
                    <div className="edumeme-section">
                        <div className="edumeme-title">
                            <h2> المحتوى </h2>
                        </div>
                        <hr />
                        <div className="edumemes-container">
                        {lessons && lessons.length > 0 ? (
                                lessons.map((lessonItem, index) => (
                                    <React.Fragment key={index}>
                                        <div className="edumemes-title">
                                            <h3>{lessonItem.title}</h3>
                                            { user.role !== 'user' && 
                                                (
                                                    <div className="buttons">
                                                        <button data-lesson={lessonItem.ID} onClick={(e) => showEditLesson(e.currentTarget.getAttribute('data-lesson'))}>
                                                            <FontAwesomeIcon icon={faPen} size="lg" />
                                                        </button>
                                                        <button data-lesson={lessonItem.ID} onClick={(e) => addEduMeme(e.currentTarget.getAttribute('data-lesson'))}>
                                                            <FontAwesomeIcon icon={faPlus} size="xs" />
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                        {eduMemes && 
                                         eduMemes.filter((edumeme) => edumeme.lesson_ID === lessonItem.ID).length > 0 ? (
                                            <div className="edumemes">
                                                {eduMemes
                                                    .filter((edumeme) => edumeme.lesson_ID === lessonItem.ID)
                                                    .map((edumeme, idx) => (
                                                        <EduMemeCard key={idx} 
                                                                    edumeme = {edumeme}
                                                                    role={user.role} />
                                                    ))}                                         
                                            </div>
                                        ) : (
                                            <div className="notfound-edumemes">
                                                <NotFound role={user.role} action={() => addEduMeme(lessonItem.ID)} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="notfound-edumemes">
                                    <NotFound role={user.role} action={() => setShowModal(true)} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <AddLessonModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    lesson={lesson}
                    setLesson={setLesson}
                    edit = {edit}
                    setEdit={setEdit}
                    onAddLesson={addLesson}
                    onEditLesson = {editLesson}
                />
            </div>
        );
    }
};

export default Lessons;
