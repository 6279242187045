import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';

const ProtectedRoute = ({ element: Component, allowedRoles }) => {
  let isAuthenticated = false;
  let role = undefined;
  const user = useAuth();

  if (user.user) {
    isAuthenticated = true;
    role = user.user.role;
  }

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/authentication/login" />;
  }

  if (!allowedRoles.includes(role)) {
    // Redirect to a "Not Allowed" page if the user does not have the required role
    return <Navigate to="/not-allowed" />;
  }

  // Render the protected component if the user is authenticated and authorized
  return Component;
};

export default ProtectedRoute;
