import Navbar from '../../hooks/Navbar'
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../../scss/pages/administration/TeamManagement.scss'

const TeamManagement = () =>{
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 1500);
    };


    return (
        <div className='TeamManagement'>
            <div className="navigation">
                <button id="add"><FontAwesomeIcon icon={faPlus} onClick={'addChapter'} size="xl" /></button>
                <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
            </div>
            {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
        </div>
    )
}

export default TeamManagement;