import { useEffect, useState } from 'react';
import '../scss/utils/ProgressCard.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { SERVER } from '../config/config';
import { useNavigate } from 'react-router-dom';
import useAuth from '../useAuth';

const ProgressCard = ({ user, subject }) => {
    const navigate = useNavigate();
    const importAll = (r) => r.keys().map(r);
    const images = importAll(require.context('../utils/progress', false, /\.(png|jpe?g|svg)$/));
    const [percentage, setPercentage] = useState(59);

    useEffect(() =>{
        const fetchStatistics = async () =>{
            try{
                const response = await axios.get(`${SERVER}/interact/percentages`, {
                    params : {
                        userUID : user.ID,
                        subjectID: subject.ID,
                    }
                })

                if (response.status === 200){
                    setPercentage(response.data.percentage)
                }
            }catch(error) {
                console.log(error)
            }
        }
        if (user && subject){
            fetchStatistics();
        }
        
    }, [user, subject])


    const getProgressImage = (percentage) => {
        if (percentage >= 0 && percentage < 20) return images[0];
        if (percentage >= 20 && percentage < 40) return images[1];
        if (percentage >= 40 && percentage < 60) return images[2];
        if (percentage >= 60 && percentage < 80) return images[3];
        if (percentage >= 80 && percentage <= 100) return images[4];
        return null;
    };

    const progressImage = getProgressImage(percentage);
    const navigatoToChapter = () =>{
        navigate(`/chapters/${subject.id}`)
    }

    return (
        <div className="progress-card" onClick={navigatoToChapter}>
            <div className="details">
                <div className="subject">
                    <img src={subject.img} alt="subject" />
                    <h2>{subject.title}</h2>
                </div>
                <div className="value">
                    <span>
                        <p>لقد قمت بإتمام تصفح</p>
                        <p><span>{percentage}%</span> من المحتوى</p>
                    </span>
                </div>
            </div>
            <div className="progress">
                <div className="progress-bar">
                    <CircularProgressbar
                        value={percentage}
                        styles={buildStyles({
                            pathColor: '#2478DC',
                            trailColor: '#d4e4f7',
                        })}
                    />
                    <div className="progress-image">
                        {progressImage && <img src={progressImage} alt="Progress" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressCard;
