// src/pages/Authentication.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import '../scss/pages/login.scss';
import canny from '../utils/mrincredible/canny.png';
import logo from '../utils/logowhite.png';
import logoMobile from '../utils/logo1.png';
import { SERVER } from "../config/config";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const Authentication = () => {
    const navigate = useNavigate();
    const { type } = useParams();
    const [activeDot, setActiveDot] = useState(1);
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showlogin, setShowLogin] = useState(type === 'login');
    const [showSignUp, setShowSignUp] = useState(false);

    // Login fields
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');

    // Sign up fields
    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/');
        }
    }, [navigate]);

    const handleCheckboxChange = () => {
        setIsChecked((prevState) => !prevState);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const createAccount = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${SERVER}/auth/register`, {
                email : emailSignUp,
                password : passwordSignUp,
                username : username
            });

            if (response.status === 201) {
                navigate('/');
            }
        } catch (error) {
            console.error(error);
            alert("An error occurred during registration");
        }
    };
    
    const login = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${SERVER}/auth/login`, {
                usernameOrEmail: emailLogin,
                password: passwordLogin
            });

            if (response.status === 200) {
                // Set the token as a cookie with expiration of 1 day
                Cookies.set('token', response.data.token, { expires: 1 });
                if (response.data.user.role === 'admin'){
                    navigate('/')
                }else{
                    navigate('/');
                }
            }
        } catch (error) {
            console.error(error);
            alert("An error occurred during login");
        }
    };

    return (
        <div className="auth">
            <div className='auth-container'>
                <img src={logoMobile} alt="Logo" className='mobile-logo' />
                <div className={showlogin ? 'login-container' : 'hidden-login'}>
                    <h1>تسجـيل الدخول</h1>
                    <form>
                        <label>البريد الإلكتروني</label>
                        <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmailLogin(e.target.value)} />
                        <label style={{ marginTop: '30px' }}>كلمة المرور</label>
                        <div className="password-input">
                            <input type={showPassword ? 'text' : 'password'} placeholder='كلمة المرور' onChange={(e) => setPasswordLogin(e.target.value)} />
                            <FontAwesomeIcon
                                icon={showPassword ? faEye : faEyeSlash}
                                onClick={togglePasswordVisibility}
                                color='#2478DC'
                                size='xs'
                            />
                        </div>
                        <div className='forget-pwd'>
                            <div className='rememberme'>
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label>تذكرني</label>
                            </div>
                            <a href=''>نسيت كلمة المرور؟</a>
                        </div>
                        <button id='submit-login' onClick={login}>تسجيل الدخول</button>
                        <button id='create' type='button' onClick={() => setShowLogin(false)}>إنشاء حساب</button>
                    </form>
                </div>
                <div className={showlogin ? 'hidden-signup' : 'signup-container'}>
                    <h1>إنشاء حساب</h1>
                    <div className="dots">
                        <div className={`dot ${showSignUp === true ? 'active' : ''}`}></div>
                        <div className={`dot ${showSignUp === false ? 'active' : ''}`}></div>
                    </div>
                    <div className='page1' style={{ display: showSignUp ? 'none' : 'flex' }}>
                        <form>
                            <label>البريد الإلكتروني</label>
                            <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmailSignUp(e.target.value)} />
                            <label style={{ marginTop: '30px' }}>كلمة المرور</label>
                            <div className="password-input">
                                <input type={showPassword ? 'text' : 'password'} placeholder='كلمة المرور' onChange={(e) => setPasswordSignUp(e.target.value)} />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    onClick={togglePasswordVisibility}
                                    color='#2478DC'
                                    size='xs'
                                />
                            </div>
                            <button id='submit-signup' type='button' onClick={() => setShowSignUp(true)}>التالي</button>
                            <button id='login' type='button' onClick={() => setShowLogin(true)}>أملك حساب</button>
                        </form>
                    </div>
                    <div className='page2' style={{ display: showSignUp ? 'flex' : 'none' }}>
                        <div>
                            <label>إسم المستخدم</label>
                            <input type='text' placeholder='إسم المستخدم' onChange={(e) => setUsername(e.target.value)} />
                            <button id='final-submit' onClick={createAccount}>إنشاء حساب</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="design">
                <div className='logo'>
                    <img src={logo} alt="Logo" />
                    <h2>الميم و التعليم</h2>
                </div>
                <img src={canny} alt="Canny" />
                <h3>يعتمد على الميمز و التعديلات لإيصال
                    المعلومة بطريقة ممتعة
                </h3>
                <div className="dots">
                    <div className={`dot ${activeDot === 0 ? 'active' : ''}`}></div>
                    <div className={`dot ${activeDot === 1 ? 'active' : ''}`}></div>
                    <div className={`dot ${activeDot === 2 ? 'active' : ''}`}></div>
                </div>
                <button onClick={() => setActiveDot((prevActiveDot) => (prevActiveDot + 1) % 3)}>التالي</button>
            </div>
        </div>
    );
}

export default Authentication;
